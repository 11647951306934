import RoadmapChapter from './RoadmapChapter';
import { getChapters } from '../../data';
import Banner from '../../assets/photo/maks 2.png'
import Banner1 from '../../assets/photo/Winter_Hat.png'
import Banner2 from '../../assets/photo/aaaa.png'
import "./index.css";

const chapters = getChapters();

const Roadmap = () => (
  <section id="roadmap">
    <div className="back-of-team" >
      <div className="back2">
        <h3 className="title1">ROADMAP</h3>
      </div>
    </div>
    <div className="chapters_section">
      <div className="chapters">
        {chapters.map((chapter, idx) => (
          <RoadmapChapter key={idx} idx={idx + 1} {...chapter} />
        ))}
      </div>
      <div className="chapter_images">
        <img src={Banner} />
        <img src={Banner1} />
        <img src={Banner2} />
      </div>
    </div>
  </section>
);

export default Roadmap;