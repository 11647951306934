import TeamMember from './TeamMember';
import { getTeamMembers } from '../../data';
import {  Container, Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

const teamMembers = getTeamMembers();

const Team = () => (
  <section id='team'>
    <div className="back-of-team">
      <div className="back2">
      <h1 className="title1">The team</h1>
      </div>
    </div>
    <div className="cards">
      <Container>
        <Row>
        {teamMembers.map((member, idx) => (
        <Col key={idx} xs={6} lg={4}>
          <TeamMember {...member} />
        </Col>
      ))}
        </Row>
      </Container>
    </div>
  </section>
);

export default Team;