export const CONTENT_ENG = {
    welcome: 'welcome to',
    training: 'the training',
    module: 'module',
    basics: 'NFT Basics',
    registry: 'Registration for the required services',
    trade: 'Your First NFT Trade',
    services: 'Useful services for NFT analysis',
    find: 'How and where to find a quality project',
    scam: 'Scam in NFT',
    influencers: 'Influencers',
    influencersContent: 'In our final module, we will provide you with links to all useful influencers in the sphere. As well as YouTube channels, discord groups, both paid and free. We recommend you to install Twitter application on your smartphone, and turn on notifications from all Influencers on the list, in order not to miss anything, as well as to watch for updates on YouTube channels and get new information, and of course do not forget about the discord, because there you can find new friends, which will help you in the future!',
};


export const CONTENT_RUS = {
    welcome: 'Добро пожаловать ',
    training: 'в обучение',
    module: 'Модуль',
    basics: 'Основы NFT',
    registry: 'Регистрация в необходимых сервисах',
    trade: 'Ваш первый NFT трейд',
    services: 'Полезные сервисы для анализа NFT',
    find: 'Как и где найти качественный проект',
    scam: 'Скам в NFT',
    influencers: 'Инфлюенсеры',
    influencersContent: 'В нашем финальном модуле, мы предоставим вам ссылки на всех полезных инфлюенсеров в сфере. А так же YouTube каналы, дискорд группы, как платные, так и бесплатные. Мы рекомендуем установить приложение Twitter на ваш смартфон, и включить уведомления у всех инфлюенсеров из списка, дабы ничего не пропустить, так же, следить за обновлениями на YouTube каналах, и черпать новую информацию, и конечно же не забывайте про дискорд, ведь именно там вы можете найти новые знакомства, которые очень вам помогут в будущем!',
};