import { useState, useContext, useCallback, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { DAppContext } from "../../context";
import "./index.css";

const Minting = () => {
  const [count, setCount] = useState(1);
  const {
    mint,
    isBlocked,
    connectWallet,
    account = "",
    loading,
    contractDetails,
    mintPrice
  } = useContext(DAppContext);
  
  const handleMint = useCallback(() => {

    mint(count);
  }, [count]);

  const changeCount = (add) => {
    const tokensPerPurchase = contractDetails?.isPresaleActive ? 2 : contractDetails?.maxAllowedTokensPerPurchase;

    let newCount = count;
    if (add) {
      if(count + 1 <= 5){
        newCount = count + 1;
      } else {
        newCount = 5;
      }
    } else if (count > 1) {
      newCount = count - 1;
    }

    
    setCount(() => tokensPerPurchase && newCount >= parseInt(tokensPerPurchase) ? tokensPerPurchase : newCount);
  };

  useEffect(() => {
    setCount(1);
  }, [])
  

  return (
    <div className="minting">
      <Container className="h-100">
        <Row className="h-100">
          <div className="grey-back">
            <h1>Minting</h1>
            <div className="price-limits">
              <p>Price | {mintPrice || 0.05} ETH Per NFT</p>
              <p>
                Limits |{" "}
                5 Per Transaction
              </p>
            </div>
            <div id="counter">
              <span
                className="plus-minus"
                onClick={() => {
                  changeCount(false);
                }}
              >
                -
              </span>
              <span>{count}</span>
              <span
                className="plus-minus"
                onClick={() => {
                  changeCount(true);
                }}
              >
                +
              </span>
            </div>
            {!account ? (
              <button type="button" onClick={connectWallet}>
                Connect Wallet
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMint}
                className="mint--btn"
                disabled={isBlocked}
              >
                Mint
              </button>
            )}
            {loading && <h5 className='text-white loading--text'>Loading...</h5>}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Minting;
