import Mask from '../../assets/photo/mask.svg';
import Brand from '../../assets/photo/brand.png';
import Discord from '../../assets/photo/discord.svg';
import Twitter from '../../assets/photo/twitter.svg';
import Insta from '../../assets/photo/instagram.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

const Footer = () => (
  <>
    <footer className="footer">
        <img src={Mask} alt="mask" />
        <a href="/" className="footer_brand">
          <img src={Brand} alt="Brand Identity" />
        </a>
        <div className="footer-right">
            <h2>join the community</h2>
            <div className="social">
              <a href="https://discord.gg/squirrelsflow" target='_blank' rel="noopener noreferrer"><img src={Discord} alt="discord" /></a>  
              <a href="https://twitter.com/squirrelsflow?s=21" target='_blank' rel="noopener noreferrer"><img src={Twitter} alt="twitter" /></a>
              <a href="https://instagram.com/squirrelsflow?utm_medium=copy_link" target='_blank' rel="noopener noreferrer"><img src={Insta} alt="instagram" /></a>
            </div>
            <a href="https://discord.gg/squirrelsflow" target='_blank' rel="noopener noreferrer" className="join_us_btn mt-4">Join Discord</a>
        </div>
    </footer>
  </>
);

export default Footer;