import { useState } from 'react';
import { getQuestions } from '../../data';
import Question from './Question';
import { Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

const FAQ = () => {
  const [questions, setQuestions] = useState(getQuestions());

  const handleQuestionOpen = idx => {
    setQuestions(questions.map((question, index) => {
      question.isExpanded = index === idx ? !question.isExpanded : false;
      return question;
    }));
  };

  return (
    <div className="questions" id="faq">
      <h1 className="text-white text-center title1" >FAQ</h1>
      <div className="qu">
        <Container>
          {questions.map((question, idx) => (
            <Question key={idx} idx={idx} {...question} handleQuestionOpen={handleQuestionOpen} />
          ))}
        </Container>
      </div>
    </div>
  );
};

export default FAQ;