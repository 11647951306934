import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DAppContext } from "../../context";
import styles from "./verificationBanner.module.css";
import BannerImage from "../../assets/header_img.png";
import BannerCheck from "../../assets/check.png";

const VerificationBanner = () => {
  const [address, setAddress] = useState("Wallet Address");
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    connectWallet,
    account = "",
    contractDetails,
  } = useContext(DAppContext);

  const navigateToLessons = () => {
    navigate("/lessons");
  };

  useEffect(() => {
    !!account.length && setAddress(account);
    !!account.length && setLoading(true);
  }, [account]);

  useEffect(() => {
    if (!!contractDetails) {
      const { balanceOf } = contractDetails.methods;
      const checkBalance = async (address) => {
        const checkBalance = await balanceOf(address)?.call();
        if (parseInt(checkBalance) > 0) {
          setRedirect(true);
        }
        setLoading(false);
      };
      checkBalance(account);
    }
  }, [contractDetails]);
console.log(account);
  return (
    <section className={styles.header_banner}>
      <div className={styles.header_container}>
        <div className={styles.header_banner_text}>
          <h1>
            do you want
            <p>to train with us?</p>
          </h1>
          <div className={styles.header_banner_subscribe}>
            <div className={styles.header_banner_input}>
              <div className={`${styles.header_banner_wrapper}`}>{address}</div>
              {loading && !redirect && <span className={styles.loader}>Loading...</span>}
              {redirect && <span className={styles.success}>Congratulations! You can go to the lessons</span>}
              {!redirect && account && !loading && <span>There is no NFT in this wallet</span>}
            </div>
            {!redirect ? (
              <button
                type="button"
                onClick={connectWallet}
                className={styles.btn_connect_wallet}
              >
                Connect Wallet
              </button>
            ) : (
              <button
                type="button"
                onClick={navigateToLessons}
                className={styles.btn_connect_wallet}
              >
                Go to Lessons
              </button>
            )}
          </div>
        </div>
        <div className={styles.header_banner_img}>
          <img src={BannerImage} alt="Squirrel" />
          <span className={styles.header_banner_circle_bg}></span>
        </div>
      </div>
    </section>
  );
};

export default VerificationBanner;
