import Vector from '../../assets/photo/Vector.svg'
import Vector1 from '../../assets/photo/Vector1.svg'

const Question = ({ question, isExpanded, answer, idx, handleQuestionOpen }) => (
  <div className={`${isExpanded ? "question1" : "question"} faq--item`} onClick={() => handleQuestionOpen(idx)}>
    <div className="quiz">
      <h2>{question}</h2>
      {isExpanded && <p>{answer}</p>}
    </div>
    <img src={isExpanded ? Vector : Vector1} alt="vector" />
  </div>
);

export default Question;