import styles from "./footer.module.css";
import discordIcon from "../../assets/discord_icon.svg";
import twitterIcon from "../../assets/twitter_icon.svg";
import instagramIcon from "../../assets/instagram_icon.svg";
import Brand from "../../assets/photo/brand.png";
import { Link } from "react-router-dom";

const LessonFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_inner_wrap}>
        <div className={styles.footer_inner}>
          <div className={styles.footer_logo}>
            <Link to="/" className={styles.footer_brand}>
              <img src={Brand} alt="Squirrels Brand" />
            </Link>
          </div>
          <div className={styles.footer_community}>
            <h4>join the community</h4>
            <div className={styles.social_community}>
              <a href="#">
                <img src={discordIcon} />
              </a>
              <a href="#">
                <img src={twitterIcon} />
              </a>
              <a href="#">
                <img src={instagramIcon} />
              </a>
            </div>
            <button className={styles.btn_join}>Join Discord</button>
          </div>
        </div>
        <div className={styles.footer_copyright}>
          Copyright © 2022 Dabookie. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default LessonFooter;
