import { useState, useEffect } from "react";
import {  Container, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

const Countdown = ({setTimerToggle}) => {
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  useEffect(() => {
    let countDownDate = new Date(Date.UTC(2022, 3, 28, 13, 0, 0)).getTime();

    let interval = setInterval(function () {
      let now = new Date().getTime();
      let distance = countDownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);

      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        setTimerToggle(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setTimerToggle]);
  return (
    <div className="countdown">
        <div className="countdown_bg">
        </div>
        <div className="coutdown_rotate">
        <Container className="h-100">
          <div className="countdown_content">
            <Col md={6}>
            <p className="countdown_head">COUNTDOWN</p>
            </Col>
            <Col md={6}>
              <div className="countdown_text_time">
                <span>{timerDays}</span>
                <span>{timerHours}</span>
                <span>{timerMinutes}</span>
                <span>{timerSeconds}</span>
              </div>
            </Col>
          </div>
        </Container>
        </div>
    </div>
  )
};

export default Countdown;