import React, { createContext, useState, useEffect, useRef } from "react";
import abi from "../contracts/abi.json";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import Web3 from "web3";
import Web3Utils from "web3-utils";
import detectEthereumProvider from "@metamask/detect-provider";

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

const CONTRACT_ADDRESS = "0x3024c800Cb11e528744056184fE2792a75C76f0F";
const CORRECT_NET_ID = 1;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const instanceRef = useRef();
  const accountRef = useRef('');
  const detailsRef = useRef('');
  const [instance, setInstance] = useState(null);
  const [accounts, setAccounts] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [networkId, setNetworkId] = useState(4);
  const [contractDetails, setContractDetails] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const web3Context = useWeb3React();

  const { connector, activate, deactivate, active } = web3Context;
  const currentConnector = injected;

  const syncContractDetails = async (provider) => {
    const web3 = new Web3(provider);
    const instance = new web3.eth.Contract(abi, CONTRACT_ADDRESS);

    const accounts = await web3.eth.getAccounts();
    const networkID = await web3.eth.net.getId();
    
    // if (parseInt(networkID) !== CORRECT_NET_ID) return alert("Please change to MainNet");
    instanceRef.current = instance;
    accountRef.current = accounts[0];
    setInstance(instance);
    setAccounts(accounts[0]);
    setNetworkId(Number(networkID));
  };

  const connectWallet = async () => {
    setLoading(true);
    try {
      await activate(injected);
      const provider = await detectEthereumProvider();
      await syncContractDetails(provider);
      setLoading(false);
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const weiToEther = (wei) => {
    return Web3Utils.fromWei(wei);
  };

  const mint = async (count) => {
    try {
      setLoading(true);
      const instances = instance || instanceRef.current;
      const account = accountRef.current || accounts;
      if (!instances) return toast.error(`No instance`);
      if (!account)
        return toast.error(`No account selected. Try reauthenticating`);
      if (!count) return toast.error(`No token count provided.`);

      const { isActive, isPresaleActive, mint, preSaleMint, presalePrice, mintPrice, allowListMaxMint, balanceOf } =
        instances.methods;

      const checkBalance = await balanceOf(account)?.call();
      
      const isPublicSaleActive = await isActive()?.call();
      const isPresaleActivated = await isPresaleActive()?.call();
      const maxForPresale = await allowListMaxMint()?.call();
      const publicETHPrice = weiToEther(`${await mintPrice()?.call()}`);
      const presaleETHPrice = weiToEther(`${await presalePrice()?.call()}`);
      let presaleCount = count;
      const price = isPresaleActivated ? presaleETHPrice : publicETHPrice;
      
      
      const convertPrice = Web3Utils.toWei(
        (Number(price) * count).toString(),
        "ether"
      );

      if (isPublicSaleActive && isPresaleActivated)
        return toast.error(`Error in Contract. Method is activated wrongly`);

      if (!isPublicSaleActive && !isPresaleActivated)
        return toast.error(`Sales has not start yet`);

      if(count > parseInt(maxForPresale)){
        toast.error(`Max for presale is 2`);
        presaleCount = maxForPresale;
      }

      if (isPresaleActivated) {
        if(Number(checkBalance) >= 2){
          toast.error('You can not mint any more Squirrels NFT');
          setIsBlocked(true);
          return;
        }
        return await preSaleMint(presaleCount)
          .send({
            from: account,
            value: Web3Utils.toWei(
              (Number(price) * presaleCount).toString(),
              "ether"
            ),
          })
          .once("error", (err) => {
            toast.error(err.message);
          })
          .then((success) => {
            if (success?.status) {
              setTransactionHash(success.transactionHash);
              toast.success(
                "Congratulations. Your NFT's successfully claimed"
              );
              setLoading(false);
            }
          });
      }

      return await mint(count)
        .send({
          from: account,
          value: convertPrice,
        })
        .once("error", (err) => {
          toast.error(err.message);
        })
        .then((success) => {
          if (success?.status) {
            setTransactionHash(success.transactionHash);
            toast.success("Congratulations. Your NFT's successfully claimed");
            setLoading(false);
          }
        });
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const getContractDetails = async () => {
    if (!instance) return null;
    let details = {};

    const {
      isPresaleActive,
      isActive,
      mintPrice,
      name,
      maximumAllowedTokensPerPurchase,
      MAX_SUPPLY,
      presalePrice,
      tokensMinted = () => {},
    } = instance.methods;

    const isPresaleActivated = await isPresaleActive()?.call();
    const isPublicSaleActive = await isActive()?.call();
    const collectionName = await name()?.call();
    const maxAllowedTokensPerPurchase =
      await maximumAllowedTokensPerPurchase()?.call();
    const totalSupplyNFT = await MAX_SUPPLY()?.call();
    const publicETHPrice = weiToEther(`${await mintPrice()?.call()}`);
    const presaleETHPrice = weiToEther(`${await presalePrice()?.call()}`);

    const alreadyMinted = await tokensMinted()?.call();
    details.price = publicETHPrice;

    if(isPresaleActivated) {
      details.price = presaleETHPrice;
    }

    details = {
      ...details,
      isPresaleActive: isPresaleActivated,
      isPublicSaleActive,
      collectionName,
      maxAllowedTokensPerPurchase,
      totalSupplyNFT,
      alreadyMinted,
      methods: instance.methods,
    };
    detailsRef.current = {
      ...details,
      isPresaleActive: isPresaleActivated,
      isPublicSaleActive,
      collectionName,
      maxAllowedTokensPerPurchase,
      totalSupplyNFT,
      alreadyMinted,
    };
    setContractDetails(details);

    if (!isPresaleActivated && !isPublicSaleActive) {
      setContractDetails({
        ...details,
        saleIsClosed: true,
      });
    }
  };

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

  useEffect(() => {
    setConnected(() => currentConnector === connector);
  }, [active, currentConnector, connector]);

  useEffect(() => {
    getContractDetails();
  }, [instance]);

  return (
    <DAppContext.Provider
      value={{
        connectWallet,
        mint,
        active,
        loading,
        transactionHash,
        resetTransactionHash,
        connected,
        contractDetails,
        account: accounts,
        details: detailsRef.current,
        isBlocked,
        deactivate,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
