import { useState, useEffect,useContext } from "react";
import LessonHeader from "../../components/LessonHeader";
import LessonFooter from "../../components/LessonFooter";
import styles from "./lesson.module.css";
import discord from "../../assets/discord.svg";
import twitter from "../../assets/twitter.svg";
import youtube from "../../assets/youtube.svg";
import { CONTENT_RUS, CONTENT_ENG } from "./constants";
import { DAppContext } from "../../context";

const Lesson = () => {
  const {
    account = "",
  } = useContext(DAppContext);

  const [contentType, setContentType] = useState("ENG");
  const [content, setContent] = useState(CONTENT_ENG);
  const [urlType, setUrlType] = useState(process.env.REACT_APP_CONTENT_ENG);

  useEffect(() => {
    contentType === "RUS" ? setContent(CONTENT_RUS) : setContent(CONTENT_ENG);
    contentType === "RUS" ? setUrlType(process.env.REACT_APP_CONTENT_RUS) : setUrlType(process.env.REACT_APP_CONTENT_ENG);
  }, [contentType]);

  if(!account) return null;

  return (
    <>
      <LessonHeader setContentType={setContentType} />
      <main className={styles.main}>
        <section className={styles.content}>
          <div className={styles.content_title}>
            <h2>
              {content.welcome}
              <p>{content.training}</p>
            </h2>
            <div className={styles.content_blink}></div>
          </div>
          <section className={styles.modules_wrap}>
            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 1</h4>
                <h3>{content.basics}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}1.mp4`} alt="nft name" />
              </div>
            </article>

            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 2</h4>
                <h3>{content.registry}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}2.mp4`} alt="nft name" />
              </div>
            </article>

            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 3</h4>
                <h3>{content.trade}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}3.mp4`} alt="nft name" />
              </div>
            </article>

            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 4</h4>
                <h3>{content.services}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}4.mp4`} alt="nft name" />
              </div>
            </article>

            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 5</h4>
                <h3>{content.find}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}5.mp4`} alt="nft name" />
              </div>
            </article>

            <article className={styles.module}>
              <div className={styles.module_text}>
                <h4 className={styles.moduleTitle}>{content.module} 6</h4>
                <h3>{content.scam}</h3>
              </div>
              <div className={styles.module_img}>
                <video controls src={`${urlType}6.mp4`} alt="nft name" />
              </div>
            </article>
            <article className={`${styles.module} ${styles.module_with_blink}`}>
              <div
                className={`${styles.module_text} ${styles.module_text_no_image}`}
              >
                <h4 className={styles.moduleTitle}>{content.module} 7</h4>
                <h3>{content.influencers}</h3>
                <p>{content.influencersContent}</p>
              </div>
              <div className={styles.module_blink}></div>
            </article>
          </section>

          <section className={styles.social_section}>
            <div className={`${styles.social_module} ${styles.discord}`}>
              <div className={styles.social_module_img}>
                <img src={discord} alt="discord" />
              </div>

              <div className={styles.social_module_text_wrap}>
                <div className={styles.social_module_text__link}>
                  <h4>Best free Discord alpha groups</h4>
                  <a href="https://discord.gg/flur" rel="noopener noreferrer">
                    https://discord.gg/flur
                  </a>
                  <a
                    href="https://discord.gg/Nfc5YxUz"
                    rel="noopener noreferrer"
                  >
                    https://discord.gg/Nfc5YxUz
                  </a>
                  <a
                    href="http://discord.com/invite/SJeX7GQJbg"
                    rel="noopener noreferrer"
                  >
                    http://discord.com/invite/SJeX7GQJbg
                  </a>
                </div>

                <div className={styles.social_module_text__link}>
                  <h4>Best paid Discord alpha groups</h4>
                  <a
                    href="https://discord.gg/hj7nHUCc"
                    rel="noopener noreferrer"
                  >
                    https://discord.gg/hj7nHUCc
                  </a>
                  <a
                    href="https://discord.gg/popeyespicks"
                    rel="noopener noreferrer"
                  >
                    https://discord.gg/popeyespicks
                  </a>
                  <a
                    href="https://discord.gg/YfjNdqWw"
                    rel="noopener noreferrer"
                  >
                    https://discord.gg/YfjNdqWw
                  </a>
                </div>
              </div>
            </div>

            <div className={`${styles.social_module} ${styles.twitter}`}>
              <div className={styles.social_module_img}>
                <img src={twitter} alt="twitter" />
              </div>

              <div className={styles.social_module_text_wrap}>
                <div className={styles.social_module_text__link}>
                  <h4>Useful Twitter Influencers</h4>

                  <a
                    href="https://twitter.com/farokh"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/farokh
                  </a>
                  <a
                    href="https://twitter.com/takoa__"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/takoa__
                  </a>
                  <a
                    href="https://twitter.com/ZssBecker"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/ZssBecker
                  </a>
                  <a
                    href="https://twitter.com/NonFungies"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/NonFungies
                  </a>
                  <a
                    href="https://twitter.com/TheShamdoo"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/TheShamdoo
                  </a>
                  <a
                    href="https://twitter.com/allnick"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/allnick
                  </a>
                  <a
                    href="https://twitter.com/champtgram"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/champtgram
                  </a>
                  <a
                    href="https://twitter.com/BAYC2745"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/BAYC2745
                  </a>
                </div>

                <div className={styles.social_module_text__link}>
                  <a
                    href="https://twitter.com/ryandcrypto"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/ryandcrypto
                  </a>
                  <a
                    href="https://twitter.com/PopeyesNFTs"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/PopeyesNFTs
                  </a>
                  <a
                    href="https://twitter.com/KosherPlug"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/KosherPlug
                  </a>
                  <a
                    href="https://twitter.com/thebrettway"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/thebrettway
                  </a>
                  <a
                    href="https://twitter.com/JRNYcrypto"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/JRNYcrypto
                  </a>
                  <a
                    href="https://twitter.com/AmeerHussainn"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/AmeerHussainn
                  </a>
                  <a href="https://twitter.com/Banks" rel="noopener noreferrer">
                    https://twitter.com/Banks
                  </a>
                  <a
                    href="https://twitter.com/OttoSuwenNFT"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/OttoSuwenNFT
                  </a>
                </div>

                <div className={styles.social_module_text__link}>
                  <a
                    href="https://twitter.com/punk6529"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/punk6529
                  </a>
                  <a
                    href="https://twitter.com/DonteCrypto"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/DonteCrypto
                  </a>
                  <a
                    href="https://twitter.com/flurnft"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/flurnft
                  </a>
                  <a
                    href="https://twitter.com/Zeneca_33"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/Zeneca_33
                  </a>
                  <a
                    href="https://twitter.com/NFTLlama"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/NFTLlama
                  </a>
                  <a
                    href="https://twitter.com/pranksy"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/pranksy
                  </a>
                  <a
                    href="https://twitter.com/llamaverse_"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/llamaverse_
                  </a>
                  <a
                    href="https://twitter.com/nftpasta"
                    rel="noopener noreferrer"
                  >
                    https://twitter.com/nftpasta
                  </a>
                </div>
              </div>
            </div>

            <div className={`${styles.social_module} ${styles.youtube}`}>
              <div className={styles.social_module_img}>
                <img src={youtube} alt="youtube" />
              </div>

              <div className={styles.social_module_text_wrap}>
                <div className={styles.social_module_text}>
                  <h4>NFT Influencers on YouTube</h4>

                  <p>
                    NFT Nate, ryandcrypto, Alex Becker’s Channel, Donte Walker
                    NFT, The Bitcoin Express, NFT Times, Matt’s Crypto, Sajad,
                    Crypto Brando, Champ Crypto, Popeyes Picks, Brett
                    Malinowski, Sebastian Ghiorghiu
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
      <LessonFooter />
    </>
  );
};

export default Lesson;
