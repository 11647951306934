const TeamMember = ({ imgSrc, iconSrc, title, imgAlt, iconAlt, url }) => (
  <div className="team_card">
    {/* <a href={url}>
      <img src={iconSrc} alt={iconAlt} className="twitter-icon" />
    </a> */}
    <img src={imgSrc} alt={imgAlt} />
    <div className="names">
      <h2>{title}</h2>
    </div>
  </div>
);

export default TeamMember;