import Banner from '../../assets/photo/maks 2.png'
import Banner1 from '../../assets/photo/Winter_Hat.png'
import Banner2 from '../../assets/photo/aaaa.png'
import Banner3 from '../../assets/photo/maks 1.png'
import Banner4 from '../../assets/photo/j.png'
import "./index.css";

const SquirrelsImages = () => (
  <div className="squirrels_images">
    <img src={Banner} />
    <img src={Banner1} />
    <img src={Banner2} />
    <img src={Banner3} />
    <img src={Banner4} />
  </div>
);

export default SquirrelsImages;