import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DAppProvider } from "./context";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import App from "./App";
import Lesson from "./pages/Lesson";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <DAppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="lessons" element={<Lesson />} />
          </Routes>
        </BrowserRouter>
      </DAppProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
