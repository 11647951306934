import { useRef } from "react";
import styles from "./header.module.css";
import Brand from "../../assets/photo/brand.png";
import { Link } from "react-router-dom";

const LessonHeader = ({setContentType}) => {
  const currentBtn = useRef(null);
  const handleContentChange = (e) => {
    const target = e.currentTarget;
    const languageType = target.dataset.lang;

    if(target.classList.contains('selected')) return;
    
    currentBtn.current.classList.contains('selected') && currentBtn.current.classList.remove('selected');

    target.classList.add('selected');
    setContentType(languageType);
    currentBtn.current = target;


  }
  
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/" className={styles.header_logo}>
          <img src={Brand} alt="Squirrels Brand" />
        </Link>
        <div className={styles.header_language_switch}>
          <button onClick={handleContentChange} data-lang="RUS" className="main_btn">Русский</button>
          <button ref={currentBtn} onClick={handleContentChange} data-lang="ENG" className="main_btn selected">English</button>
        </div>
      </div>
    </header>
  );
};

export default LessonHeader;
