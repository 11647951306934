import { useState } from 'react';
import { Toaster } from "react-hot-toast";
import Header from './components/Header';
import NftCollection from './components/NftCollection';
import Countdown from './components/Countdown';
import SquirrelsImages from './components/SquirrelsImages';
import AboutProject from './components/AboutProject';
import Team from './components/Team';
import FAQ from './components/FAQ';
import Footer from "./components/Footer";
import Video from './components/Video';
import Roadmap from './components/Roadmap/Index';
import Minting from './components/Minting';
import VerificationBanner from './components/VerificationBanner';
import './App.css';


function App() {
  const [isCountDown, setIsCountDown] = useState(false);

  return (
    <>
      <Header />
      <VerificationBanner/>
      {/* <NftCollection /> */}
      {!isCountDown ? <Countdown setTimerToggle={setIsCountDown} /> : <Minting />}
      <SquirrelsImages />
      <AboutProject />
      <Video />
      <Roadmap />
      <div className="component">
        <Team />
        <FAQ />
      </div>
      <Footer />
      <Toaster position="top-center" />
    </>
  );
}

export default App;
