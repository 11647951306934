import { createRef, useState } from 'react';
import VideoBanner from '../../assets/video.mp4'
import Pol from '../../assets/photo/Polygon 1.svg'
import "./index.css";

const Video = () => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = createRef(null);
  const handleShowVideo = () => {
    setShowVideo(shwVdo => {
      if (!shwVdo) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      return !shwVdo;
    });
  };

  return (
    <div className="video-container">
      <video ref={videoRef} controls className="video" src={VideoBanner} onPause={() => setShowVideo(!showVideo)} />
      {!showVideo && <img src={Pol} onClick={handleShowVideo} />}
    </div>
  );
};

export default Video;