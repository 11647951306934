import { Navbar, Nav, NavItem, Container, Col, Row } from "react-bootstrap";
import Brand from '../../assets/photo/brand.png';
import Vec0 from '../../assets/photo/Vector0.svg';
import Twit from '../../assets/photo/twitter.svg';
import Insta from '../../assets/photo/instagram.svg';
import Opensea from '../../assets/opensea.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const Header = () => {
  return (
    <Navbar className="nav">
      <Container>
        <Row className="w-100">
          <Col
            lg={9}
            xl={7}
            className="d-flex align-items-center justify-content-between"
          >
            <a href="/" className="logo col-3">
              <img src={Brand} alt='Squirrels Brand' />
            </a>
            <Nav className="col-9">
              <NavItem>
                <a href="#about" className="nav_links">
                  About
                </a>
              </NavItem>
              <NavItem>
                <a href="#roadmap" className="nav_links">
                  Roadmap
                </a>
              </NavItem>
              <NavItem>
                <a href="#team" className="nav_links">
                  Team
                </a>
              </NavItem>
              <NavItem>
                <a href="#faq" className="nav_links">
                  FAQ
                </a>
              </NavItem>
            </Nav>
          </Col>
          <Col
            lg={3}
            xl={5}
            className="justify-content-end align-items-center d-flex header__socials"
          >
            <Nav className="col-xl-7 col-12">
              <NavItem>
                <a
                  href="https://discord.gg/squirrelsflow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Vec0}
                    className="soc_lings_images m-0"
                  />
                </a>
              </NavItem>
              <NavItem>
                <a
                  href="https://twitter.com/squirrelsflow?s=21"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Twit}
                    className="soc_lings_images"
                  />
                </a>
              </NavItem>
              <NavItem>
                <a
                  href="https://instagram.com/squirrelsflow?utm_medium=copy_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Insta}
                    className="soc_lings_images"
                  />
                </a>
              </NavItem>
              <NavItem>
                <a
                  href="https://opensea.io/collection/sqrlsflow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Opensea}
                    className="soc_lings_images"
                  />
                </a>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Header;
