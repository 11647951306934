import Tiger1 from './assets/photo/Two_color.svg';
import Twitter from './assets/photo/twitter.svg'
import Tiger2 from './assets/photo/team.png';
import Tiger3 from './assets/photo/team_3.png';
import Tiger4 from './assets/photo/team_2.png';

export const getQuestions = () => ([
  {
    question: "What is the total supply?",
    answer: "There are a total of 5,000 NFTs available for mint.",
    isExpanded: true
  },
  {
    question: "How many SQRL NFTs can I mint in the Whitelist Sale?",
    answer: "WL role holders will be able to mint 2 SQRL NFTs at the pre-sale",
    isExpanded: false
  },
  {
    question: "How many SQRL NFTs can I mint in the Public Sale?",
    answer: "At the public sale, we set a limit of 5 SQRLS to one wallet",
    isExpanded: false
  },
  {
    question: "How to mint?",
    answer: "Video guide on how to mint one of SQRLS will be available very soon in our official discord",
    isExpanded: false
  },
  {
    question: "What will be the price for one SQRL?",
    answer: "Price for SQRLS is TBA",
    isExpanded: false
  },
  {
    question: "Can I apply to be a mod in this server?",
    answer: "Yes! Just open a ticket for Mod Application :)",
    isExpanded: false
  },
  {
    question: "Can I request a new channel in this server?",
    answer: "Yes, just open a General Support ticket in our discord, or contact us via email info@sqrlsflow.com, and describe your request :)",
    isExpanded: false
  },
  {
    question: "I received a DM telling me I…",
    answer: "It's a scam.",
    isExpanded: false
  },
  {
    question: "There's a site that's minting SQRLS, is it true?",
    answer: "No, It's a scam.",
    isExpanded: false
  },
]);

export const getTeamMembers = () => ([
  {
    imgSrc: Tiger1,
    title: "Jack M. - Founder",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger2,
    title: "Sebastian G. - Co-Founder",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Sofia E. - Project Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger3,
    title: "Anthony K. - Twitter Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger4,
    title: "Noah C.- Instagram Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger2,
    title: "Michael W. - Instagram Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Elizabeth J. - TikTok Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "James A. - TikTok Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger4,
    title: "David H. - Discord Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Chloe W. - Advertising Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger2,
    title: "Mila A. - Promotion & Plan Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger3,
    title: "Camila J. - Targetologist",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "GSD CLUB and ODD REPUBLIC - Art & Development",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Charles F.  - Technical Task Manager",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger2,
    title: "Dylan O. - Accountant ",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Bella G. - Editor & Translator & Animations voiceover",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger4,
    title: "Alina A. - Course voiceover ( RU ) ",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger3,
    title: "Christopher H. - Course voiceover ( EN )",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger3,
    title: "Head Moderator - adgvkty",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Moderator - Flourefic",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger4,
    title: "Moderator - jeroni x",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger2,
    title: "Moderator - LeParisien",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Moderator - linatchi",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  },
  {
    imgSrc: Tiger1,
    title: "Moderator - s1eeze",
    iconSrc: Twitter,
    imgAlt: "squireel",
    iconAlt: "twitter-icon"
  }
]);

export const getChapters = () => ([
  {
    title: "Chapter",
    text: "Official launch of Squirrels Flow discord server and sneak peaks of our primary collection are available to the public."
  },
  {
    title: "Chapter",
    text: "WL requirements announced, major giveaways, OG role competition"
  },
  {
    title: "Chapter",
    text: "One of many lessons from course is available to everyone to check out"
  },
  {
    title: "Chapter",
    text: "Pre-sale minting website is running and becomes available for whitelisted members"
  },
  {
    title: "Chapter",
    text: "Public minting goes live for every Squirrels Flow community member"
  },
  {
    title: "Chapter",
    text: "After sold-out, full courses become available for every holder."
  },
  {
    title: "Chapter",
    text: "Launch of our private alpha group in official Squirrels Flow discord server for holders(with calls )"
  },
  {
    title: "Chapter",
    text: "Contribution in holder community with giveaways, exclusive calls, snipes and etc."
  },
  {
    title: "Chapter",
    text: "Every holder receives whey, which will give ability to turn your regular Squirrrel into Mutant one."
  },
  {
    title: "Chapter",
    text: "Second collection detailed announcement. By turning your Squirrel into Mutant ones, you will receive an advanced, improved, and more professional-based education."
  },
  {
    title: "Chapter",
    text: "After reveal, each holder of 8 legendries will have ability to have a private one hour call with founders of SQRLS Flow"
  },
]);